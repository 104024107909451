var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        {
                          staticClass: "card-header-with-button",
                          attrs: { lg: "12" }
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              "Order Detail - " +
                                _vm._s(_vm.selectedDetail.order_id)
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c("CCol", { staticClass: "mb-4", attrs: { sm: "6" } }, [
                        _c("h5", [_vm._v("Basic Info")]),
                        _c("hr"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "12pt",
                              "font-weight": "bold"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.selectedDetail.firstname) +
                                " " +
                                _vm._s(_vm.selectedDetail.lastname)
                            )
                          ]
                        ),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.selectedDetail.user_id)),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.selectedDetail.phone)),
                        _c("br"),
                        _c("br"),
                        _c("b", [_vm._v("Jogger ID:")]),
                        _vm._v(" " + _vm._s(_vm.selectedDetail.jogger_id) + " ")
                      ]),
                      _c("CCol", { attrs: { sm: "6" } }, [
                        _c("h5", [_vm._v("Restaurant")]),
                        _c("hr"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "12pt",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.selectedDetail.restaurant))]
                        ),
                        _c("br"),
                        _c("b", [_vm._v("Building:")]),
                        _vm._v(" " + _vm._s(_vm.selectedDetail.building)),
                        _c("br"),
                        _c("b", [_vm._v("Location:")]),
                        _vm._v(" " + _vm._s(_vm.selectedDetail.loc)),
                        _c("br")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { sm: "6" } },
                        [
                          _c("h5", [_vm._v("Timeline")]),
                          _c("hr"),
                          _c(
                            "CRow",
                            [
                              _c(
                                "CCol",
                                {
                                  staticStyle: { "text-align": "right" },
                                  attrs: { sm: "3" }
                                },
                                [
                                  _c("b", [_vm._v("Ordered At:")]),
                                  _c("br"),
                                  _c("b", [_vm._v("Paid At:")]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c("b", [_vm._v("Accepted At:")]),
                                  _c("br"),
                                  _c("b", [_vm._v("Completed At:")]),
                                  _c("br"),
                                  _c("b", [_vm._v("Delivered At:")]),
                                  _c("br")
                                ]
                              ),
                              _c("CCol", { attrs: { sm: "9" } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.selectedDetail.order_date)
                                ),
                                _c("br"),
                                _vm._v(" " + _vm._s(_vm.selectedDetail.paid)),
                                _c("br"),
                                _vm._v(
                                  " " + _vm._s(_vm.selectedDetail.accpt_date)
                                ),
                                _c("br"),
                                _vm._v(
                                  " " + _vm._s(_vm.selectedDetail.cmplt_date)
                                ),
                                _c("br"),
                                _vm._v(
                                  " " + _vm._s(_vm.selectedDetail.dlvrd_date)
                                ),
                                _c("br")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("CCol", { attrs: { sm: "6" } }, [
                        _c("h5", [_vm._v("Financial Info")]),
                        _c("hr"),
                        _c("b", [_vm._v("Item Total:")]),
                        _vm._v(
                          " $" +
                            _vm._s(
                              parseFloat(_vm.selectedDetail.subtotal).toFixed(2)
                            )
                        ),
                        _c("br"),
                        _c("b", [_vm._v("Delivery Fee:")]),
                        _vm._v(
                          " $" +
                            _vm._s(
                              parseFloat(_vm.selectedDetail.delivamt).toFixed(2)
                            )
                        ),
                        _c("br"),
                        _c("b", [_vm._v("Tax:")]),
                        _vm._v(
                          " $" +
                            _vm._s(
                              parseFloat(_vm.selectedDetail.taxamt).toFixed(2)
                            ) +
                            " (" +
                            _vm._s(
                              parseFloat(_vm.selectedDetail.taxrate).toFixed(2)
                            ) +
                            "%)"
                        ),
                        _c("br"),
                        _c("b", [_vm._v("Discount:")]),
                        _vm._v(
                          " $" +
                            _vm._s(
                              parseFloat(_vm.selectedDetail.discamt).toFixed(2)
                            ) +
                            " (" +
                            _vm._s(parseInt(_vm.selectedDetail.discamt)) +
                            "%)"
                        ),
                        _c("br"),
                        _c("b", [_vm._v("Receipt Amount:")]),
                        _vm._v(
                          " $" +
                            _vm._s(
                              parseFloat(_vm.selectedDetail.receiptamt).toFixed(
                                2
                              )
                            )
                        ),
                        _c("br"),
                        _c("br"),
                        _c("b", [_vm._v("Restaurant Net Amount:")]),
                        _vm._v(
                          " $" +
                            _vm._s(
                              parseFloat(_vm.selectedDetail.resnetamt).toFixed(
                                2
                              )
                            )
                        ),
                        _c("br"),
                        _c("b", [_vm._v("Jog Fee:")]),
                        _vm._v(
                          " $" +
                            _vm._s(
                              parseFloat(_vm.selectedDetail.joggfee).toFixed(2)
                            ) +
                            " (" +
                            _vm._s(parseFloat(_vm.selectedDetail.joggrate)) +
                            "%) "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { staticClass: "mt-3", attrs: { lg: "12" } },
                        [
                          _c("h5", [_vm._v("Order Items")]),
                          _c("CDataTable", {
                            attrs: {
                              items: _vm.orderItemsData,
                              striped: "",
                              "items-per-page": 10,
                              pagination: {
                                doubleArrows: false,
                                align: "center"
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }